exports.components = {
  "component---src-pages-3-d-prints-js": () => import("./../../../src/pages/3d-prints.js" /* webpackChunkName: "component---src-pages-3-d-prints-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clock-js": () => import("./../../../src/pages/clock.js" /* webpackChunkName: "component---src-pages-clock-js" */),
  "component---src-pages-field-js": () => import("./../../../src/pages/field.js" /* webpackChunkName: "component---src-pages-field-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metronome-js": () => import("./../../../src/pages/metronome.js" /* webpackChunkName: "component---src-pages-metronome-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-old-index-js": () => import("./../../../src/pages/old-index.js" /* webpackChunkName: "component---src-pages-old-index-js" */),
  "component---src-pages-op-1-tape-slicer-js": () => import("./../../../src/pages/op-1-tape-slicer.js" /* webpackChunkName: "component---src-pages-op-1-tape-slicer-js" */)
}

